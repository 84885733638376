import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Project from '../components/project'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import BlockContent from '../components/block-content'
import Img from "gatsby-image"

import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"

import '../components/about-page.css'

export const query = graphql`
query Contacts {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      description
      keywords
      aboutImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        asset {
          _id
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }
    about: sanityAbout {
      title
      _rawExperience
      clients
      links {
        url
        title
      }
    }
  }
`

const Contacts = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const about = (data || {}).about

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
        <Helmet>
        <script src={withPrefix('trackfetch.js')} type="text/javascript" />
        </Helmet>   
      <SEO title={'Contacts'} description={site.description} keywords={site.keywords} />
      <div className='about'>
        <div className='about-wrapper'>
            <div className='person'>
                <div className='description'>{about._rawExperience && <BlockContent blocks={about._rawExperience || []} />}</div>
            </div>
            <div className='experience'>
                <div className='title'>Now listening on <a href='https://open.spotify.com/user/1124780887?si=TuNpLxeWRYCGu9Ct4cYjEg' target='_blank'>Spotify</a></div>
                <div id='track'>Fetching...</div>
            </div>
        </div>
        <div className='bottom-image'>
                <Img
                    fluid={site.aboutImage.asset.fluid}
                />
        </div>
      </div>
    </Layout>
  )
}

export default Contacts
